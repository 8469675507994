/* eslint-disable max-lines */
import React, { useCallback, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage as Translation } from 'react-intl';
import { Link } from 'react-router-dom';
import withRouter from 'HOCs/withRouter';
import { withFeatureFlag as withLQFeatureFlag } from 'LaquesisEOL/withFeatureFlag';
import { useExperiment } from 'LaquesisEOL/useExperiment';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import ItemImage from 'Components/Listing/components/ItemImage';
import ReactObserver from 'Components/ReactObserver/ReactObserver';
import VasTagsBottomInfo from '../VasTagsBottomInfo/VasTagsBottomInfo';
import ItemFavouriteIcon from '../../../ItemFavouriteIcon/ItemFavouriteIcon';
import withConfig from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import withBackButtonHandling from 'HOCs/withBackButtonHandling/withBackButtonHandling';
import useItemInfo from 'Hooks/useItemInfo';
import { getVasConfigValues, getLocale, configSelector } from 'Selectors/config';
import { computeMinEMIForMinHitchAmount } from 'Selectors/financeConfig';
import { isMobile } from 'Helpers/devices';
import { isCocoFofoUser } from 'Helpers/item';
import { getFormattedAmount } from 'Helpers/numbers';
import { EMI_EXPERIMENT, ITEM_SOURCE, LQ_SHORTLIST_FEATURE } from 'Constants/items';
import { LISTING_TYPES, trustTagHash, DEFAULT_SIZE_BY_VISUALIZATION } from 'Constants/listing';
import { BLACKBACKGROUND2, MONETIZATIONDARK1 } from 'Constants/colors';
import { IS_DESKTOP, IS_MOBILE } from 'Constants/device.APP_TARGET';
import { THEMES } from 'Constants/bundles';
import css from './ItemCard.APP_TARGET.scss';
import SpinViewTag from 'Components/Listing/components/SpinViewTag';
import CompareIconCTA from 'Components/Compare/CompareIconCTA/CompareIconCTA';
import { setItem as setSessionStorageItem } from 'Helpers/sessionStorage';
import { CAR_COMPARE_FLOW_STEP } from 'Constants/tracking';
import { getVisibleCampaignTags } from 'Selectors/visibleCampaign';
import { USER_TYPE } from 'Constants/users';
import { emiNumberFormat } from 'Helpers/emiNumberFormat';
import TooltipInstallment from 'Components/TooltipInstallment/TooltipInstallment';
import { fileNames } from 'Constants/imageUrls';
import { WEBP_SVG_TYPE } from 'Constants/images';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { shortPriceFormat } from 'Helpers/shortPriceDisplay';
import { getAIGeneratedAdsVideoConfig, showVideoBadgeBasedOnConfig } from 'Helpers/aiGeneratedAdsVideo';

export const ItemCard = ({
    userType,
    chosenOption,
    className,
    config,
    enabledRealImpressions,
    friendsInCommon,
    isAdSuggested,
    item,
    listingType,
    location,
    onItemClick,
    onView,
    source,
    tag,
    trackerOrigins,
    visualizationType,
    vasConfigValues,
    financeConfig,
    currentLocale,
    onVasTagTap,
    openComparePopup,
    track,
    showOriginalTitle,
    showSubTitle,
    showSubDetails,
    showFullPrice,
    showOlxAutosAdCardTag,
    selectFrom,
    showMakeModel,
    showEmiPlaceHolder,
    showVasStrip,
    imageHeight,
    imageWidth,
    isListingPage,
    featureFlag,
    visibleCampaignTagsInfo,
    zrpWidgetType,
    isNewUspVasDesign,
    listingBodyType,
    videoBadgeFeatureConfig
}) => {
    const {
        category_id,
        displayTitle,
        displayDetails,
        id,
        image,
        isFeatured,
        isInspected,
        price,
        monthly_installment,
        rawPriceValue,
        priceCurrency,
        scoreFactor,
        slug,
        spell_id,
        spell_key,
        spell_v,
        title,
        user_id,
        user_type,
        subtitle,
        subDetails,
        make,
        model,
        isSpinViewAvailable,
        visibleCampaignTagIds,
        hasPromotion,
        discountedPrice,
        discountAmount,
        is_video_generated,
        video_url } = useItemInfo(item, config);
    const emiExperimentVariant = useExperiment(EMI_EXPERIMENT[config.get('siteCode')] || '');
    const showEmiHighlighted = emiExperimentVariant === 'b';
    const showFeaturedTag = config.get('adpvAuto', 'featuredTag');
    const isMxCl = config.get('adpvAuto', 'showMXDesign');
    const enableEmiFeature = config.get('enableEmiFeature');
    const hideVasTagListing = config.get('hideVasTagListing');
    const isCarCompareEnabled = config.get('olxAutos', 'isCarCompareEnabled');
    const showFavBtn = featureFlag === LQ_SHORTLIST_FEATURE;
    const showCompareIcon = isListingPage && isCarCompareEnabled;
    const trackselectFrom = isListingPage ? trackerOrigins.LISTING : '';
    const theme = config.get('theme', 'id');
    const smallSizeFavIcon = 14;
    const bigSizeFavIcon = 16;
    const campaignTagLabels = visibleCampaignTagIds.filter(tag => !!visibleCampaignTagsInfo[tag]?.labelName)
        .map(tag => {
            return { labelId: tag, ...visibleCampaignTagsInfo[tag] };
        });

    let status = null;

    if (showFeaturedTag && isFeatured) {
        status = (<span className={ css.status }>
            <Translation id="listing_ad_featured_label">{ val => val }</Translation>
        </span>);
    }

    const linkToProp = useMemo(() => {
        const state = { isAdSuggested, source, select_from: selectFrom };

        // Add fields for tracking related ads
        if (source === ITEM_SOURCE.RELATED) {
            state.relatedAdTracking = {
                impressions: [{
                    adId: id,
                    scoreFactor: typeof scoreFactor === 'undefined' ? 'null' : scoreFactor
                }],
                chosen_option: typeof chosenOption === 'undefined' ? 'null' : chosenOption,
                origin: trackerOrigins.ITEM_PAGE
            };
        }

        // Add tracking for featured ad
        state.featuredAdTracking = { chosen_option: typeof chosenOption === 'undefined' ? 'null' : chosenOption };

        if (location && location.state && location.state.fromBundleResultset) {
            state.fromBundleResultset = location.state.fromBundleResultset;
        }

        if (zrpWidgetType) {
            state.select_from = `rf_${zrpWidgetType}`;
        }

        if (listingBodyType) {
            state.select_from = `rf_${listingBodyType}_listing`;
        }

        return {
            pathname: isMxCl ? slug : slug.replace('/olxmobbi', ''),
            state
        };
    }, [id, slug, scoreFactor, isAdSuggested, source, chosenOption, trackerOrigins, location, selectFrom, listingBodyType, zrpWidgetType, isMxCl]);

    const emiTranslatedText = useMemo(() => {
        const minEmi = computeMinEMIForMinHitchAmount(financeConfig, rawPriceValue, enableEmiFeature);
        const formattedEmiText = getFormattedAmount(minEmi, priceCurrency, currentLocale);
        const values = {
            amt: ''
        };

        return (
            <Translation id="emiFullText" values={ values } >
                { val => (
                    <span className={ css.emi }>
                        { val }{showEmiHighlighted ? <b className={ css.emi__value }> {formattedEmiText}</b> : formattedEmiText }
                    </span>
                )}
            </Translation>
        );
    }, [currentLocale, financeConfig, priceCurrency, rawPriceValue, enableEmiFeature, showEmiHighlighted]);

    const onViewChange = useCallback(inView => {
        if (!enabledRealImpressions) {
            return;
        }
        if (inView) {
            onView({
                id,
                title,
                user_id,
                isFeatured,
                spell_id,
                spell_key,
                spell_v,
                isInspected
            });
        }
    }, [onView, id, title, user_id, isFeatured, spell_id, spell_key, spell_v, isInspected, enabledRealImpressions]);
    const onClick = useCallback(() => onItemClick(item), [item, onItemClick]);

    const campaignTags = campaignTagLabels.length
        ? campaignTagLabels.map(label => (
            <span
                data-aut-id={ label.labelId }
                className={ cx(css.campaignTag, { [css.listingCard]: isListingPage }) }
                style={ { backgroundColor: label.labelColor, color: label.labelTextColor } }>
                { label.labelName }
            </span>)
        )
        : null;
    const onComparePopupOpen = () => {
        setSessionStorageItem('compare_flow_step', CAR_COMPARE_FLOW_STEP.LISTING);
        openComparePopup();
    };
    const netPrice = hasPromotion ? discountedPrice : price;

    const isVideoAvailable = is_video_generated && video_url;
    const isSearchPage = isListingPage && (source === ITEM_SOURCE.SEARCH || source === ITEM_SOURCE.BROWSE);
    const videoAvailableBadgeSRP = (<ImageWrapper
        addSVG
        types={ WEBP_SVG_TYPE }
        supportForDifferentSize
        data-aut-id="vidAvailableSRPOLXMobbi"
        className={ css.aiVideoBadgeSRPMobbi }
        fileName={ isMobile ? fileNames.IS_AI_VIDEO_AVAIL_SM : fileNames.IS_AI_VIDEO_AVAIL_LG }
    />);

    const objInstallment = {
        amount: emiNumberFormat(monthly_installment)
    };
    const contentInstallment = <Translation id="installmentPerMonth" values={ objInstallment } />;

    return (
        <ReactObserver
            tag={ tag }
            data-aut-id="itemBox"
            data-aut-category-id={ category_id }
            onChange={ onViewChange }
            className={ cx(css[`${visualizationType}ItemCard`], css[`${visualizationType}ItemCardContent`], className, { [css.withoutVas]: !showVasStrip || hideVasTagListing, [css.hideVasTag]: hideVasTagListing || isNewUspVasDesign }) }>
            <Link
                to={ linkToProp }
                state={ linkToProp?.state }
                className={ cx({
                    [css.withStatusTagRow]: IS_MOBILE && !status && !campaignTags
                }) }
                onClick={ onClick } >
                <div className={ css.topContainer }>
                    {isSpinViewAvailable && <SpinViewTag isFeaturedTagVisible={ showFeaturedTag && isFeatured } />}
                    {image
                        && <ItemImage
                            image={ image }
                            friendsInCommon={ friendsInCommon }
                            defaultSize={ DEFAULT_SIZE_BY_VISUALIZATION[visualizationType] }
                            alt={ title }
                            visualizationType={ visualizationType }
                            listingType={ listingType }
                            height={ imageHeight }
                            width={ imageWidth }
                            videoAvailableBadge={ showVideoBadgeBasedOnConfig(videoBadgeFeatureConfig, isVideoAvailable, isSearchPage) ? videoAvailableBadgeSRP : null }
                        />
                    }
                    {IS_DESKTOP
                        && showOlxAutosAdCardTag && <div className={ cx(css.statusTags, { [css.listingCard]: isListingPage }) }>
                        {status}
                        {campaignTags}
                    </div>
                    }
                    {
                        listingType === LISTING_TYPES.ITEMS && (
                            <div className={ cx(css.resume, { [css.bp0]: showSubDetails, [css.withCarCompareIcon]: showCompareIcon }) }>
                                {IS_MOBILE
                                    && showOlxAutosAdCardTag && <div className={ css.statusTags }>
                                    {status}
                                    {campaignTags}
                                </div>
                                }
                                {price && (
                                    <div className={ cx(css.priceDetails, { [css.highlightEmi]: showEmiHighlighted }) } data-aut-id="itemPrice">
                                        <div className={ css.priceSection }>
                                            <span className={ cx(css.price, { [css.fullWidth]: showFullPrice }) } >
                                                { showEmiHighlighted ? <Translation id="carValueLabel">{ val => `${val} ${netPrice}` }</Translation> : netPrice}
                                            </span>
                                            {hasPromotion && <div className={ css.discountSection } data-aut-id="itemDiscountInfo">
                                                <span className={ css.priceBeforeDiscount } data-aut-id="itemPriceBeforeDiscount" >
                                                    {shortPriceFormat(rawPriceValue, 'IDR')}
                                                </span>
                                                <span className={ css.discountTotal } data-aut-id="itemDiscountTotal">{`-${shortPriceFormat(discountAmount)}`}</span>
                                            </div>}
                                        </div>
                                        { !(theme === THEMES.OTOPLUS) && enableEmiFeature ? emiTranslatedText : !(theme === THEMES.OTOPLUS) && showEmiPlaceHolder && <div className={ css.emiPlaceHolder } /> }
                                        {/* OLX AUTOS/Desktop/Homepage */}
                                        {(user_type === 'OLX_Autos' || user_type === 'Franchise' || user_type === 'Preferred' || userType === 'OLX_Autos' || userType === 'Franchise' || userType === 'Preferred') && monthly_installment !== 0 ? (
                                            <div className={ css.installment }>
                                                <span className={ css.titleInstallment } data-aut-id="itemInstallment">
                                                    {contentInstallment}
                                                </span>
                                                <span className={ css.tooltipInstallment }>
                                                    {IS_MOBILE ? (
                                                        <TooltipInstallment item={ item } size={ 'mobile' } />
                                                    ) : (
                                                        <TooltipInstallment item={ item } size={ !(theme === THEMES.OTOPLUS) && showEmiPlaceHolder ? 'large' : 'small' } />
                                                    )}
                                                </span>
                                            </div>
                                        ) : (
                                            <div className={ css.noShowInstallments } />
                                        )}
                                    </div>
                                )}

                                {/* variant #1: show display title created */}
                                {!showOriginalTitle && displayTitle && (
                                    <h3 className={ css.title } title={ displayTitle } data-aut-id="itemTitle">{displayTitle}</h3>
                                )}
                                {/* variant #2: show original title in api response */}
                                {showOriginalTitle && !showMakeModel && <div className={ css.originalTitle } title={ title }>{title}</div>}
                                {/* variant #1: show displayDetails i.e fuel,km_driven,itemLocation */}
                                {!showSubTitle && displayDetails && <div className={ css.details } data-aut-id="itemDetails">{
                                    displayDetails.map((detail, index) => (
                                        <React.Fragment>
                                            {index !== 0 && <span className={ css.detSep }>|</span>}
                                            {detail}
                                        </React.Fragment>)
                                    )
                                }</div>}
                                {/* variant #2: show subtitle ie. year-km_driven */}
                                {showSubTitle && <div className={ css.subtitle }>
                                    {subtitle}
                                </div>}
                                {showMakeModel && make && model
                                    && <div
                                        className={ css.title }
                                        title={ title }
                                        data-aut-id="itemTitle"
                                    >
                                        {`${make} ${model}`}
                                    </div>}
                                {showSubDetails && (
                                    <div className={ css.subDetailsContainer }>
                                        {subDetails?.itemLocation && (
                                            <div className={ css.itemLocation }>
                                                <Icon icon="location" size={ 16 } />
                                                <span className={ css.locationText }>
                                                    {subDetails.itemLocation}
                                                </span>
                                            </div>
                                        )}
                                        {subDetails?.displayDate && <span className={ css.displayDate }>{subDetails.displayDate}</span>}
                                    </div>
                                )}
                            </div>
                        )
                    }
                </div>
                {listingType === LISTING_TYPES.ITEMS && !hideVasTagListing && showVasStrip && !isNewUspVasDesign && (
                    <VasTagsBottomInfo
                        className={ css.bottomCardInfo }
                        itemId={ id }
                        vasConfigValues={ vasConfigValues }
                        gradient={ isCocoFofoUser(user_type || userType) ? MONETIZATIONDARK1 : BLACKBACKGROUND2 }
                        onVasTagTap={ onVasTagTap }
                        onTrack={ track }
                        visualizationType={ visualizationType }
                    />
                )}
            </Link>
            {
                showFavBtn
                && listingType === LISTING_TYPES.ITEMS
                && <ItemFavouriteIcon
                    item={ item }
                    className={ css.favIcon }
                    btnClassName={ css.favBtn }
                    size={ isMobile ? smallSizeFavIcon : bigSizeFavIcon }
                    color="white"
                    selectFrom={ trackselectFrom }
                />
            }
            {
                showCompareIcon && (
                    <CompareIconCTA
                        itemId={ id }
                        className={ cx(css.compareIconCTA, { [css.hideVasTag]: hideVasTagListing || isNewUspVasDesign }) }
                        openComparePopup={ onComparePopupOpen }
                        isListingPage
                        isFirstItemCard={ chosenOption === 0 }
                    />
                )
            }
            {hasPromotion && <ImageWrapper
                fileName={ visualizationType === 'grid' ? fileNames.DISCOUNT_ICON_MEDIUM : fileNames.DISCOUNT_ICON_SMALL }
                types={ WEBP_SVG_TYPE }
                addSVG
                supportForDifferentSize
                className={ css.discountIcon }
                data-aut-id="iconDiscount"
            />}
            { showVideoBadgeBasedOnConfig(videoBadgeFeatureConfig, isVideoAvailable, !isSearchPage) && <ImageWrapper
                addSVG
                types={ WEBP_SVG_TYPE }
                supportForDifferentSize
                className={ css.aiVideoBadge }
                data-aut-id="vidAvailableHomeOLXMobbi"
                fileName={ fileNames.IS_AI_VIDEO_AVAIL_SM }
            /> }
        </ReactObserver>
    );
};

ItemCard.propTypes = {
    userType: PropTypes.string,
    config: PropTypes.object.isRequired,
    item: PropTypes.object,
    onView: PropTypes.func,
    className: PropTypes.string,
    friendsInCommon: PropTypes.bool,
    trackerOrigins: PropTypes.object,
    enabledRealImpressions: PropTypes.bool,
    visualizationType: PropTypes.oneOf([
        'grid', 'big', 'list', 'smallGrid'
    ]),
    listingType: PropTypes.string,
    onItemClick: PropTypes.func,
    tag: PropTypes.string,
    isAdSuggested: PropTypes.bool,
    source: PropTypes.string,
    chosenOption: PropTypes.number,
    location: PropTypes.object,
    vasConfigValues: PropTypes.array,
    financeConfig: PropTypes.object,
    currentLocale: PropTypes.string,
    onVasTagTap: PropTypes.func,
    openComparePopup: PropTypes.func,
    track: PropTypes.func.isRequired,
    showOriginalTitle: PropTypes.bool,
    showSubTitle: PropTypes.bool,
    showSubDetails: PropTypes.bool,
    showFullPrice: PropTypes.bool,
    showOlxAutosAdCardTag: PropTypes.bool,
    showMakeModel: PropTypes.bool,
    showEmiPlaceHolder: PropTypes.bool,
    showVasStrip: PropTypes.bool,
    selectFrom: PropTypes.string,
    imageHeight: PropTypes.number,
    imageWidth: PropTypes.number,
    isListingPage: PropTypes.bool,
    isNewUspVasDesign: PropTypes.bool,
    featureFlag: PropTypes.string,
    zrpWidgetType: PropTypes.string,
    visibleCampaignTagsInfo: PropTypes.object,
    listingBodyType: PropTypes.string,
    videoBadgeFeatureConfig: PropTypes.object
};

ItemCard.defaultProps = {
    userType: '',
    item: {},
    className: '',
    onView: () => { },
    friendsInCommon: false,
    visualizationType: 'grid',
    enabledRealImpressions: false,
    listingType: LISTING_TYPES.ITEMS,
    onItemClick: () => { },
    tag: 'li',
    isAdSuggested: false,
    chosenOption: null,
    trackerOrigins: {},
    vasConfigValues: [],
    financeConfig: {},
    currentLocale: '',
    onVasTagTap: () => {},
    openComparePopup: () => {},
    showOriginalTitle: false,
    showSubTitle: false,
    showSubDetails: false,
    showFullPrice: false,
    showOlxAutosAdCardTag: true,
    showMakeModel: false,
    showEmiPlaceHolder: true,
    showVasStrip: true,
    isListingPage: false,
    featureFlag: '',
    zrpWidgetType: '',
    visibleCampaignTagsInfo: {},
    listingBodyType: '',
    videoBadgeFeatureConfig: null
};

export const mapStateToProps = (state, ownProps) => {
    const user = state.users.elements[ownProps.item.user_id];
    const userVasTags = user && user.additional_services || [];

    return {
        userType: user && user.dealer && user.dealer.car && user.dealer.car.dealer_type || USER_TYPE.REGULAR,
        currentLocale: getLocale(state),
        trackOrigin: state.track.origin,
        selectFrom: ownProps.selectFrom || (ownProps.location.state && ownProps.location.state.source),
        vasConfigValues: getVasConfigValues(state, ownProps.item.vasTags || userVasTags || []),
        financeConfig: state.financeConfig || {},
        visibleCampaignTagsInfo: getVisibleCampaignTags(state),
        videoBadgeFeatureConfig: getAIGeneratedAdsVideoConfig(configSelector(state).features)
    };
};

export default compose(
    withConfig,
    withTrack,
    withRouter,
    withBackButtonHandling(trustTagHash),
    withLQFeatureFlag(LQ_SHORTLIST_FEATURE),
    connect(mapStateToProps, null)
)(ItemCard);
